"use client";

import React from "react";
import { usePathname } from "next/navigation";
import { usePrevious } from "@gonoodle/gn-universe-utils";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { find } from "lodash";

import { useUser } from "../../contexts/user";
import * as OneTrust from "../../contexts/OneTrust";
import { USER_TYPES } from "../../constants";
import BugsnagClient from "../../utils/bugsnag";

const segment = new AnalyticsBrowser();

export default function BootstrapClientAnalytics() {
  const pathname = usePathname();
  const { user, updateUser } = useUser();
  const performanceConsentStatus = OneTrust.useConsentStatus(
    OneTrust.PERFORMANCE_SDKS,
  );
  const prevPerformanceConsentStatus = usePrevious(performanceConsentStatus);

  React.useEffect(() => {
    if (performanceConsentStatus === OneTrust.CONSENT_GIVEN) {
      segment
        .load(
          {
            writeKey: process.env.NEXT_PUBLIC_ANALYTICS_API_KEY,
          },
          { initialPageview: true },
        )
        .catch((err) => BugsnagClient.notify(err));
    }
  }, [performanceConsentStatus]);

  React.useEffect(() => {
    if (
      prevPerformanceConsentStatus === OneTrust.CONSENT_GIVEN &&
      performanceConsentStatus === OneTrust.CONSENT_NOT_GIVEN
    ) {
      segment?.reset();
      window.location.reload();
    }
  }, [performanceConsentStatus, prevPerformanceConsentStatus]);

  React.useEffect(() => {
    segment.page({
      referrer: "", // disable referrer tracking for privacy
    });
  }, [pathname]);

  React.useEffect(() => {
    if (user?.analyticsId && user?.userTypeId) {
      segment.ready(() => {
        segment.identify(user?.analyticsId, {
          user_type: find(USER_TYPES, { id: user.userTypeId })?.name,
        });
      });
    }
  }, [user?.analyticsId, user?.userTypeId]);

  React.useEffect(() => {
    if (
      user?.allowTracking === false &&
      performanceConsentStatus === OneTrust.CONSENT_GIVEN
    ) {
      updateUser({ allowTracking: true });
    } else if (
      user?.allowTracking === true &&
      performanceConsentStatus === OneTrust.CONSENT_NOT_GIVEN
    ) {
      updateUser({ allowTracking: false });
    }
  }, [user?.allowTracking, updateUser, performanceConsentStatus]);

  return null;
}
