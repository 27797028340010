import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/apps/web/components/BootstrapClientAnalytics/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/apps/web/contexts/Providers/index.jsx");
;
import(/* webpackMode: "eager" */ "/workspace/assets/styles/tailwind.css");
;
import(/* webpackMode: "eager" */ "/workspace/assets/styles/vars.css");
;
import(/* webpackMode: "eager" */ "/workspace/assets/styles/utils.css");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/local/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../../assets/fonts/degular.woff2\",\"style\":\"normal\",\"weight\":\"800\",\"variable\":\"--font-degular\"}],\"variableName\":\"degular\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/local/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../../assets/fonts/degular-display.woff2\",\"style\":\"italic\",\"weight\":\"800\",\"variable\":\"--font-degular-display\"}],\"variableName\":\"degularDisplay\"}");
